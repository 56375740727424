<template>
  <div
    id="cardForCessaotype"
    :class="cardClasses"
    :style="object.valueTot > 0 ? '' : 'pointer-events: none !important;'"
    @click="fetchFromCard(object.name)"
  >
    <div class="d-flex classTitleMain">
      <div class="titleFromCard" id="titleFromCardCessao" v-html="object.title"></div>
    </div>
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column cardTotalAndPercent">
        <v-card-title class="titleNumber">{{ object.valueTot }}</v-card-title>
        <v-card-subtitle class="headerClass">{{ object.percentual }}{{ object.description }}</v-card-subtitle>
      </div>
      <div v-if="loading" class="text-center d-flex flex-column">
        <v-progress-circular
        :width="14"
        :size="80"
        color="#F7F7FD"
        indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="d-flex flex-column apexchartDiv">
        <apexCharts
          class="apexchart"
          width="140"
          height="140"
          :options="chartOptions"
          :series="object.value"
          type="donut"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';

export default {
  name: 'CardByStatus',
  props: {
    object: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      chartOptions: {
        chart: {
          width: 150,
          height: 150,
        },
        states: {
          active: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },

        dataLabels: {
          enabled: false,
          // formatter: function (value) {
          //   let a = 2070 - Number(value);

          //   return (value - a).toFixed() + ' %';
          // },
        },
        plotOptions: {
          pie: {
            customScale: 0.79,
          },
        },
        stroke: {
          colors: ['E7E7FA'],
        },
        tooltip: {
          enabled: false,
        },
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#ECE7F5',
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: this.object.color,
      },
    };
  },

  computed: {
    sit() {
      return this.situation;
    },
    colorForCard() {
      return this.object.color;
    },
    cardClasses() {
      let classes = '';
      classes += !this.hasData && this.object.name !== 'totCessao' ? 'cardForCessaoTypeDisabled ' : '';
      classes += this.object.status === true ? 'cardForCessaotypeEnable' : 'cardForCessaotype';
      return classes;
    },
    hasData() {
      return this.object.valueTot > 0;
    }
  },
  methods: {
    ...mapActions('esocial/esocialCedidos/', ['fetchFromCard']),
  },
};
</script>
<style scoped lang="scss">
@import './src/design/variables.scss';

.v-progress-circular {
  margin: 10px 0 0 0;
}

.classTitleMain {
  height: 60px;
  max-height: 60px;
}

@media (max-width: 1920px) {
  .cardForCessaotypeEnable,
  .cardForCessaotype {
    height: 212px !important;
    max-height: 212px !important;
    transition: height ease 150ms;
    width: 379px;
    max-width: 379px;
    .v-progress-circular {
      margin: 34px 0 0 0;
    }
    .headerClass {
      text-wrap: wrap;
    }
    
  }
  ::v-deep {
    .apexcharts-canvas {
      right: -29px;
      bottom: -15px;
    }
  }
}
@media (min-width: 1920px) {
  .cardForCessaotypeEnable,
  .cardForCessaotype {
    width: 565px;
    max-width: 565px;
  }
  ::v-deep {
    .apexcharts-canvas {
      left: 28px;
      bottom: 8px;
    }
  }
}

.cardForCessaotype,
.cardForCessaotypeEnable {
  transition: height ease-in-out 150ms;
  height: 188px;
  max-height: 188px;
  padding: 16px;
  border-radius: $border-radius-sm;
  &:nth-child(-n + 2) {
    margin-right: 16px;
  }
}

.cardForCessaotype {
  border: 1px solid #e7e7fa;
  background-color: #f7f7fd;
  &:hover {
    background-color: $brand-color-highlight-light;
  }
  cursor: pointer;
}
.cardForCessaotypeEnable {
  border-top: 4px solid #00ff99 !important;
  border-bottom: 1px solid #e7e7fa !important;
  border-left: 1px solid #e7e7fa !important;
  border-right: 1px solid #e7e7fa !important;
  box-shadow: none !important;
}

.cardForCessaoTypeDisabled {
  background-color: $neutral-color-low-dark;
}

.cardTotalAndPercent {
  padding-right: 32px;
}
.headerClass {
  white-space: nowrap;
  color: var(--AG-Capital-Neutral-Color-High-Medium, #52526b);

  /* Fontes/Regular/XS */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  padding-left: 0;
}
.titleNumber {
  min-height: 80px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 53.2px */
  letter-spacing: 0.4px;
  padding-left: 0;
}
.v-tooltip__content {
  white-space: wrap;
  word-break: normal;
  opacity: 1;
}
.frame-28 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.group-26 {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  position: static;
}

.titleFromCard {
  color: var(--ag-capital-brand-tokens-primary-medium, #442d96);
  text-align: left;
  font-family: var(--fontes-regular-sm-font-family, 'Roboto-Regular', sans-serif);
  font-size: var(--fontes-regular-sm-font-size, 20px);
  line-height: var(--fontes-regular-sm-line-height, 150%);
  font-weight: var(--fontes-regular-sm-font-weight, 400);
  position: relative;
  flex: 1;
}
</style>
